























import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  props: {
    search: {
      type: String,
      required: false,
      default: "",
    },
  },

  setup(props, { root }) {
    const state = reactive({
      headers: [
        { value: "index", text: root.$tc("layout.misc.orderNumber"), width: 1 },
        { value: "name", text: "Nazwa" },
        { value: "isActive", text: "Status", sortable: false, align: "right" },
        { value: "actions", text: "", align: "right", width: 1 },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ["name"],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      search: "",
      searchTimeout: undefined as any,
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/symposium`, {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
            search: state.search || undefined,
          },
        })
        .then(({ data: { symposiums, total } }) => {
          state.empty = false;
          state.items = symposiums;
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    watch(
      () => props.search,
      (newSearch) => {
        state.search = newSearch;
        clearTimeout(state.searchTimeout);
        state.searchTimeout = setTimeout(fetchData, 500);
      }
    );

    onMounted(fetchData);
    watch(() => state.options, fetchData, { deep: true });

    return { state, fetchData };
  },
});
